import React, { useLayoutEffect, useRef, useState } from 'react'
import gsap from 'gsap'

interface SlideLoopProps {
    slidePath: string[]
    numberInViewDesktop: number
    numberInViewMobile: number
    velocity: number
    direction: 'left' | 'right'
    showBadgeRecentWork?: boolean
}

const SlideLoop: React.FC<SlideLoopProps> = (props) => {
    const loopRef = useRef<HTMLDivElement>(null);
    const [numberInView, setNumberInView] = useState(props.numberInViewDesktop);

    // Function to update the numberInView based on screen size
    const updateNumberInView = () => {
        const isMobile = window.innerWidth < 768; // You can adjust the breakpoint as needed
        setNumberInView(isMobile ? props.numberInViewMobile : props.numberInViewDesktop);
    }

    useLayoutEffect(() => {
        updateNumberInView();
        window.addEventListener('resize', updateNumberInView);

        return () => {
            window.removeEventListener('resize', updateNumberInView);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        if (loopRef.current) {
            const loopWidth = 100;
            gsap.to(loopRef.current, {
                xPercent: props.direction === 'left' ? -loopWidth : loopWidth,
                duration: props.velocity * numberInView,
                repeat: -1,
                ease: 'none'
            });
        }
    }, [props.direction, numberInView, props.velocity]);

    const items = props.slidePath.map((x, i) => (
        <div
            key={i}
            className={`flex-shrink-0 w-[calc(100%/${numberInView})]`}>
            <img src={`${process.env.PUBLIC_URL}${x}`} alt="our work" />
        </div>
    ));

    return (
        <div className="relative w-full pt-9">
            {renderBadgeRecentWork()}
            <div className={`flex items-baseline ${props.showBadgeRecentWork && 'mt-24'} ${props.direction === 'right' && 'justify-end'}`}>
                <div ref={loopRef} className="loop flex whitespace-nowrap gap-4 pl-4">
                    {items}
                    {items} {/* Duplicazione per effetto di loop continuo */}
                </div>
            </div>
        </div>
    );

    function renderBadgeRecentWork() {
        if (!props.showBadgeRecentWork) return null;
        return (
            <div className="absolute left-[15%] top-14 md:top-4 z-10 max-w-28 lg:max-w-48">
                <img className='animate-spin-slow' src={`${process.env.PUBLIC_URL}/assets/circle.svg`} alt="badge recent work" />
            </div>
        );
    }
}

export default SlideLoop;
