import React from 'react'
import { Dictionary } from '../../constants/content'

interface AviableTextProps {
    typeButton?: boolean
}

const AviableText: React.FC<AviableTextProps> = (props) => {
    return (
        <div className={`w-fit justify-center gap-3 items-center my-7 inline-flex ${props.typeButton && 'py-2 px-4 border border-x-gray rounded-full'}`}>
            <span className="relative flex h-3 w-3">
                <span className="bg-secondary animate-ping absolute inline-flex h-full w-full rounded-full"></span>
                <span className="bg-secondary relative inline-flex rounded-full h-3 w-3 border border-primary"></span>
            </span>
            <span className={`${props.typeButton && 'text-white'}`}>{Dictionary.AVAILABLE_TEXT}</span>
        </div>
    )
}

export default AviableText