import React, { useLayoutEffect } from 'react'
import { Dictionary } from '../../constants/content'
import TextReveal from '../TextReveal/TextReveal'
import gsap from 'gsap'
import ButtonPlan from '../ButtonPlan/ButtonPlan'
import AvailableText from '../AvailableText/AvailableText'

const Hero = () => {

    useLayoutEffect(() => {
        const cursors = document.querySelectorAll('.cursor');
        cursors.forEach(cursor => {
            if (window.innerWidth > 650) animateCursor(cursor);
            else {
                gsap.to('.cursor', {
                    xPercent: 50,
                    y: 1000,
                    duration: 2,
                    delay: 1,
                    ease: "power1.inOut",
                    stagger: .08,
                    onComplete: () => {
                        cursor.classList.add('hidden')
                    }
                });
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const animateCursor = (cursor: Element) => {
        const randomDuration = () => Math.random() * 3 + 1;
        const randomPosition = (axis: 'x' | 'y') => `${Math.random() * 90 - 10}%`;

        gsap.to(cursor, {
            x: randomPosition('x'),
            y: randomPosition('y'),
            duration: randomDuration(),
            ease: "power1.inOut",
            onComplete: () => animateCursor(cursor),
            //repeat: -1,
            //repeatRefresh: true
        });
    }

    return (
        <section className='w-full'>
            <div className="container px-4 mx-auto px-4 text-center">
                <div className="text-center w-full mt-10 md:mt-36 relative">
                    {renderTypography()}
                    {renderCursor()}
                </div>
                <div className="w-full flex justify-center mt-8 md:mt-10">
                    <ButtonPlan/>
                </div>
                <AvailableText/>
            </div>
        </section>
    );

    function renderTypography(){
        return(
            <React.Fragment>
                <h1 className='font-machina text-4xl md:text-7xl xl:text-8xl font-bold'>
                    <TextReveal text={Dictionary.HERO_TITLE}/>
                </h1>
                <p className='max-w-144 text-lg sm:text-xl mx-auto mt-6 md:mt-9'>
                    <TextReveal text={Dictionary.HERO_DESCRIPTION}/>
                </p>
            </React.Fragment>
        )
    }

    function renderCursor(){
        const cursorImages = ['Multiplayer-1', 'Multiplayer-2', 'Multiplayer-3', 'Multiplayer-4', 'Multiplayer-5'];
        const positionTop = ['-10%', '14%', '26%', '50%', '70%'];
        const positionLeft = ['30%', '0%', '60%', '40%', '15%' ]
        return cursorImages.map((x: any, i: any) => (
            <div key={i}
                className="cursor absolute pointer-events-none"
                style={{top: positionTop[i], left: positionLeft[i]}}>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/cursor/${x}.svg`}
                    alt="cursor-profession"
                    loading='lazy'/>
            </div>
        ))
    }
}

export default Hero