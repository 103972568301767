import React, { useEffect, useState } from 'react';

interface FaqProps {
    id: string
    title: string;
    description: string;
}

const Faq: React.FC<FaqProps> = (props) => {

    const [opened, setOpened] = useState(false);

    useEffect(() => {
        if (opened) {
            const itemsOpen = document.querySelectorAll('.faq.open');
            itemsOpen.forEach((item) => {
                if (item !== document.getElementById(props.id)) {
                    const desc = (item as HTMLElement).querySelector('.desc');
                    const iconLine = (item as HTMLElement).querySelector('.icon__line rect');
                    desc?.classList.remove('max-h-144');
                    desc?.classList.add('max-h-0');
                    iconLine?.classList.remove('h-0');
                    item.classList.remove('open');
                    item.classList.add('close');
                }
            });
        }
    }, [opened, props.id]);

    return (
        <div
            id={props.id}
            onClick={() => setOpened(!opened)}
            className={`faq ${opened ? 'open' : 'close'} w-full py-9 border-b border-gray border-opacity-30 cursor-pointer`}>
            <div className="flex justify-between items-baseline gap-4">
                <h6 className='text-lg sm:text-2xl'>{props.title}</h6>
                {renderIcon()}
            </div>
            <div className={`desc overflow-hidden transition-max-height duration-500 ease-[cubic-bezier(0.44, 0.07, 0.43, 0.96)] ${opened ? 'max-h-144' : 'max-h-0'}`}>
                <p className='text-gray-dark text-lg sm:text-xl pt-4 max-w-4xl leading-relaxed'>
                    {props.description}
                </p>
            </div>
        </div>
    );

    function renderIcon(){
        return (
            <div className={`min-w-[14px] sm:min-w-[18px] text-primary transition-all duration-500 ${opened && 'rotate-90'}`}>
                <svg viewBox="0 0 18 18" fill="var(--text-primary)" xmlns="http://www.w3.org/2000/svg" className="icon relative" data-v-3162fdc4="">
                    <rect x="8" width="2" height="18" className="fill-current" data-v-3162fdc4=""></rect>
                    <g className="icon__line" data-v-3162fdc4="">
                        <rect x="18" y="8" width="2" height="18" transform="rotate(90 18 8)" className={`fill-current transition-all ${opened && 'h-0'}`} data-v-3162fdc4=""></rect>
                    </g>
                </svg>
            </div>
        );
    }

}

export default Faq;
