import React from 'react'

interface CardBenefitProps {
    iconPath: string
    title: string
    description: string
}

const CardBenefit: React.FC<CardBenefitProps> = (props) => {
    return (
        <div  style={{transform: `rotate(${Math.random() * (2 - (-2)) + -2}deg)`}} className='w-full pt-16 md:pt-4 sm:aspect-square border border-primary border-opacity-10 flex flex-col items-center justify-center p-4 rounded-xl relative'>
            <img className='absolute top-4 left-1/2 -translate-x-1/2' src={`${process.env.PUBLIC_URL}/assets/icons/icon-pin.svg`} alt="pin" />
            <img className='absolute top-4 left-4' src={`${process.env.PUBLIC_URL}${props.iconPath}`} alt="icon card" />
            <h6>{props.title}</h6>
            <p className='text-lg md:text-xl font-bold mt-2'>
                {props.description}
            </p>
        </div>
    )
}

export default CardBenefit