import React from 'react'
import { Dictionary } from '../../constants/content'

const ButtonPlan = () => {
    return (
        <a href={'#pricing'} className='text-lg bg-primary rounded-full text-[#fff] px-8 py-3 cursor-pointer transition-all hover:bg-secondary hover:text-primary w-fit'>
            {Dictionary.CTA_PLAN}
        </a>
    )
}

export default ButtonPlan