import React, { useEffect } from 'react';
import Hero from './components/Hero/Hero';
import SlideLoop from './components/SlideLoop/SlideLoop';
import { Dictionary } from './constants/content';
import TextReveal from './components/TextReveal/TextReveal';
import ButtonPlan from './components/ButtonPlan/ButtonPlan';
import CardBenefit from './components/CardBenefit/CardBenefit';
import CardJoinUs from './components/CardJoinUs/CardJoinUs';
import CardPayment from './components/CardPayment/CardPayment';
import Faq from './components/Faq/Faq';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { initScroll } from './assets/js/scrollToSection';
import gsap from 'gsap';

function App() {

	useEffect(() => {
		initScroll();

		// gsap.to('.banner-t', {
		// 	xPercent: -100, repeat: -1, duration: 5, ease: 'linear'
		// })

	})

	return (
		<div className="w-full m-0 p-0">
			{/* {renderBannerBlackFriday()} */}
			<Header/>
			<Hero />
			{renderOurWork()}
			{renderOurBrandLogos()}
			{renderSectionIllustration()}

			<div className="w-full bg-gray-light py-14 float-left">
				{renderTags()}
				{renderCards()}
				{renderTablePrice()}
				{renderFAQs()}
			</div>
			{renderPreFooter()}
			<Footer/>
			{/* <CookieBanner/> */}
		</div>
	);

	function renderOurWork() {
		return (
			<section className='w-screen overflow-hidden relative'>
				<div className="container px-4 mx-auto md:my-7">
					<img className="mx-auto md:float-end mt-14 md:mt-0" src={`${process.env.PUBLIC_URL}/assets/certificati.png`} alt="badge clutch base" />
				</div>
				<SlideLoop
					slidePath={Dictionary.IMAGES_WORKS}
					numberInViewDesktop={4}
					numberInViewMobile={2}
					velocity={4}
					direction='left'
					showBadgeRecentWork />
			</section>
		);
	}

	function renderOurBrandLogos() {
		return (
			<div className="w-full text-center py-14 overflow-x-hidden">
				<p className='text-lg opacity-70'>
					<TextReveal text={Dictionary.TEXT_BRANDS_LOGOS} />
				</p>
				{/* <SlideLoop
					slidePath={Dictionary.IMAGES_BRANDS_LOGOS}
					numberInViewDesktop={6}
					numberInViewMobile={2}
					velocity={5}
					direction='left' /> */}
			</div>
		);
	}

	// function renderBannerBlackFriday(){
	// 	const today = new Date();
	// 	const endDate = new Date('2024-11-30');
	// 	if (today >= endDate) return;
		
	// 	return (
	// 		<div className="w-full flex nowrap">
	// 			<div className="w-full bg-primary bg-gradient-to-r from-primary to-primary py-2 md:py-4 text-white overflow-hidden flex gap-6 text-center">
	// 				{Array(6).fill(null).map((_, i) => (
	// 					<p key={i} className="banner-t whitespace-nowrap"> 🦄 Black friday -35% Sul primo mese ⚡️ valido fino al 29 Nov</p>
	// 				))}
	// 			</div>
	// 		</div>
	// 	);
	// }

	function renderSectionIllustration() {
		return (
			<section id={'how-it-works'} className='w-full'>
				<div className="container px-4 mx-auto">
					<h2 className='text-4xl md:text-7xl text-center mx-auto font-machina font-bold max-w-5xl my-7'>
						<TextReveal text={Dictionary.ILLUSTRATION_TITLE} />
					</h2>
					<p className='text-lg md:text-xl max-w-xl my-6 mx-auto text-center'>
						<TextReveal text={Dictionary.ILLUSTRATION_DESCRIPTION} />
					</p>
					{renderBoxIllustrations()}
					<div className="w-full my-14 flex justify-center">
						<ButtonPlan />
					</div>
				</div>
			</section>
		);
	}

	function renderBoxIllustrations() {
		return (
			<div className="w-full grid grid-cols-1 md:grid-cols-3 gap-5 mt-14 md:mt-24">
				{Dictionary.ILLUSTRATION_BOX.map((x, i) => (
					<div key={i} className="w-full flex items-center justify-center flex-col text-center gap-6">
						<img src={x.IMAGE} alt="Onflow illustration" />
						<p className='max-w-64'>{x.TEXT}</p>
					</div>
				))}
			</div>
		);
	}

	function renderTags() {
		return (
			<div className="w-full float-left px-3">
				<div className="max-w-2xl mx-auto text-center">
					{Dictionary.TAGS.map((x, i) => (
						<div key={i} className={`w-fit float-left py-2 px-6 inline-block rounded-full text-white text-sm sm:text-lg mt-2 mr-2`} style={{ backgroundColor: x.COLOR }}>
							{x.TEXT}
						</div>
					))}
				</div>
			</div>
		);
	}

	function renderCards() {
		return (
			<div id="benefit"className="w-full float-left">
				<div className="container px-4 mx-auto py-20 text-center">
					<h3 className='text-6xl md:text-8xl font-machina font-bold my-7'>
						<TextReveal text={Dictionary.BENEFIT_TITLE} />
					</h3>
					<p className='text-lg max-w-128 mx-auto'>
						<TextReveal text={Dictionary.BENEFIT_DESCRIPTION} />
					</p>
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-20 gap-4">
						{Dictionary.BENEFIT_CARDS.map((x, i) => (
							<CardBenefit key={i} iconPath={x.ICON} title={x.TITLE} description={x.SUB_TITLE} />
						))}
					</div>
				</div>
			</div>
		);
	}

	function renderTablePrice() {
		return (
			<div id="pricing" className="w-full float-left mb-9">
				<div className="container px-4 mx-auto">
					<div className="grid grid-col-1 lg:grid-cols-12 gap-8">
						<div className="lg:col-span-5 order-2 lg:order-1">
							<CardJoinUs/>
						</div>
						<div className="lg:col-span-7 order-1 lg:order-2">
							<CardPayment/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	function renderFAQs(){
		return (
			<div id="faq" className="w-full float-left py-24">
					<div className="max-w-full px-6 sm:px-12 grid grid-cols-6 border-b border-gray border-opacity-30 pb-16 sm:pb-24">
						<span className='hidden sm:block col-span-full sm:col-span-1 md:col-span-2 text-xl'>FAQ</span>
						<h3 className='col-span-full sm:col-span-4 md:col-span-3 text-2xl md:text-5xl font-machina font-bold'>
							<TextReveal text={Dictionary.FAQ_TITLE}/>
						</h3>
				</div>
				<div className="w-full px-6 sm:px-12 mx-auto grid grid-cols-6">
					{Dictionary.FAQ_LIST.map((x, i) => (
						<div key={i} className="col-span-full md:col-span-4 md:col-start-3 sm:col-start-2 sm:col-span-5">
							<Faq id={`f-${i}`} title={x.TITLE} description={x.DESCRIPTION}/>
						</div>
					))}
				</div>
			</div>
		);
	}

	function renderPreFooter(){
		
		return (
			<div className="w-full bg-primary flex flex-col p-8 pb-24 text-center">
				<div className="max-w-3xl mx-auto text-white">
					{renderAvatars()}
					<h5 className='text-4xl sm:text-5xl md:text-7xl font-machina font-bold '>
						<TextReveal text={Dictionary.PRE_FOOTER_TITLE}/>
					</h5>
					<p className="text-lg sm:text-xl py-7">
						<TextReveal text={Dictionary.PRE_FOOTER_DESCRIPTION}/>
					</p>
					<a className='py-4 px-9 rounded-full bg-white text-primary text-lg mt-10 inline-block transition-all hover:bg-secondary'
						href={Dictionary.LINK_GET_IN_TOUCH} target="_blank" rel="noopener noreferrer">
						{Dictionary.CTA_GET_IN_TOUCH}
					</a>
					<div className="mt-4">
						<span>{Dictionary.TEXT_SEND_MAIL} </span>
						<a className='underline'
							href="mailto:contact@onflow.it" rel="noopener noreferrer">
							contact@onflow.it
						</a>
					</div>
				</div>
			</div>
		);
	}

	function renderAvatars(){
		return (
			<div className="flex gap-3 justify-center w-full my-9">
				{new Array(5).fill(undefined).map((x, i) => (
					<img key={i} src={`${process.env.PUBLIC_URL}/assets/avatar/avatar-0${++i}.svg`} alt="" />
				))}
			</div>
		);
	}
}

export default App;
