import React from 'react'
import { Dictionary } from '../../constants/content'

const Header = () => {
    return (
        <header className='w-full p-5 top-0 mx-auto z-50'>
            <div className="flex items-center justify-between max-w-7xl mx-auto">
                <img className='max-w-28 sm:max-w-36' src={`${process.env.PUBLIC_URL}/logo-black.svg`} alt="logo onflow" />
                <div className='hidden sm:flex gap-5 flex-wrap items-center justify-center'>
                    {Dictionary.NAVIGATION.map((x, i) => (
                        <a key={i} href={x.link} 
                            rel="noopener noreferrer"
                            className='text-lg hover:underline'>
                            {x.title}
                        </a>
                    ))}
                </div>
                <a className='bg-primary py-2 px-5 rounded-full text-base sm:text-lg text-white hover:bg-secondary hover:text-primary'
                    href={Dictionary.LINK_LOGIN} rel="noopener noreferrer">
                    Let's chat
                </a>
            </div>
        </header>
    )
}

export default Header