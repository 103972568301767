import React, { useState } from 'react'
import { Dictionary } from '../../constants/content'
import TextReveal from '../TextReveal/TextReveal';

const CardPayment = () => {

    const [content, setContent] = useState('STANDARD')

    return (
        <div className='w-full h-full border border-primary border-opacity-30 p-4 sm:p-5 md:p-8 rounded-3xl'>
            {renderTag()}
            {renderTagButton()}
            <h6 className='text-5xl sm:text-6xl md:text-8xl font-machina mt-9 flex items-baseline gap-4'>
                <TextReveal text={(Dictionary.PAYMENT_TABLE as any)[content].PRICE}/>
                <span className='text-xl'>
                    <TextReveal text={content.includes('STANDARD') ? '/mese' : '/mese '}/>
                </span>
            </h6>
            <p className='w-full lg:text-lg border-b border-primary border-opacity-30 mt-4 pb-7 mb-6'>
                <TextReveal text={(Dictionary.PAYMENT_TABLE as any)[content].DESCRIPTION}/>
            </p>
            <p className='font-semibold'>
                <TextReveal text={(Dictionary.PAYMENT_TABLE as any)[content].LIST_TITLE}/>
            </p>
            {renderList()}
            {renderButton()}
        </div>
    );

    function renderTagButton(){
        const plan = ['Standard', '⚡️ Pro'];

        return (
            <div className='w-full mt-4'>
                <div className="border p-1 flex w-fit gap-2 rounded-full relative">
                    {plan.map(x => (
                        <React.Fragment key={x}>
                            <div
                                onClick={() => setContent(x.includes('Pro') ? 'PRO' : 'STANDARD')}
                                className={`w-32 p-2 text-lg md:p-3 md:text-xl rounded-full text-center cursor-pointer z-10 ${content.includes(x.toUpperCase().replace('⚡️ ', '')) && 'text-white'}`}>
                                {x}
                            </div>
                            <span className={`absolute bg-primary w-1/2 top-0 left-0 h-full rounded-full border-4 border-white transition-all ${content === 'PRO' && 'translate-x-full'}`}></span>
                        </React.Fragment>
                    ))}  
                </div>
            </div>
        );
    }


    function renderTag(){
        return (
            <div className="w-full sm:text-right">
                <div className='text-sm w-fit bg-secondary inline-block rounded-full py-2 px-4'>
                    {(Dictionary.PAYMENT_TABLE as any)[content].PAYMENT_TAG_TEXT}
                </div>
            </div>
        );
    }

    function renderList(){
        return (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-9">
                {(Dictionary.PAYMENT_TABLE as any)[content].LIST.map((x: any, i: any) => (
                    <div key={i} className="flex w-full gap-2 sm:gap-4 items-center">
                        <img className='w-4 sm:w-6' src={process.env.PUBLIC_URL + '/assets/icons/icon-check-outline.svg'} alt="" />
                        <p><TextReveal text={x.TEXT}/></p>
                        {x.TAG && <div className='w-fit py-1 px-2 bg-secondary rounded-full text-sm h-fit'>{x.TAG}</div>}
                    </div>
                ))}
            </div>
        );
    }

    function renderButton(){
        return (
            <div className="flex flex-col md:flex-row mt-16 items-center gap-2">
                <a className="transition-all py-4 px-9 bg-primary text-white rounded-full hover:bg-secondary hover:text-primary" href={(Dictionary.PAYMENT_TABLE as any)[content].LINK_STRIPE} target="_blank" rel="noopener noreferrer">
                    {(Dictionary.PAYMENT_TABLE as any)[content].LINK_TEXT_BUTTON}
                </a>
                <div>
                    <span>{Dictionary.TEXT_OR} </span>
                    <a className="underline" href={Dictionary.LINK_GET_IN_TOUCH} target="_blank" rel="noopener noreferrer">
                        {Dictionary.CTA_GET_IN_TOUCH}
                    </a>
                </div>
            </div>
        );
    }
}

export default CardPayment