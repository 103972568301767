import React from 'react';
import './reveal.css';


interface TextRevealProps {
	text: string;
}

const TextReveal: React.FC<TextRevealProps> = ({ text }) => {

	return <span className='split-line' dangerouslySetInnerHTML={{__html: text}}>
		</span>;
};

export default TextReveal;
