import React from 'react'
import AviableText from '../AvailableText/AvailableText'
import TextReveal from '../TextReveal/TextReveal'
import { Dictionary } from '../../constants/content'

const CardJoinUs = () => {
    return (
        <div className='bg-primary w-full rounded-3xl pt-0 pb-7 px-7 md:pt-4 md:pb-12 md:px-12'>
            <AviableText typeButton/>
            <h5 className='text-5xl sm:text-6xl xl:text-7xl text-white font-machina font-bold'>
                <TextReveal text={Dictionary.JOINUS_TITLE}/>
            </h5>
            <p className='w-full text-lg md:text-lg text-white mt-0 mb-12'>
                <TextReveal text={Dictionary.JOINUS_TEXT}/>
            </p>
            <div className="w-full border border-gray p-4 md:p-8 flex flex-col rounded-xl">
                {Dictionary.JOINUS_LINKS.map((x, i) => (
                    <a key={i} href={x.LINK} target='_blank' rel="noreferrer" className='text-white flex gap-3 md:gap-14 justify-between py-7 border-gray transition-all first:border-b group'>
                        <div>
                            <span className='text-xl md:text-3xl w-full block mb-4 transition-all group-hover:underline'>{x.TITLE}</span>
                            <span className='text-sm opacity-80'>{x.DESCRIPTION}</span>
                        </div>
                        <img className="group-hover:translate-x-2 transition-all" src={`${process.env.PUBLIC_URL}/assets/icons/icon-arrow-long.svg`} alt="arrow" />
                    </a>
                ))}
            </div>
        </div>
    )
}

export default CardJoinUs