import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export const scrollToSection = (targetId: any) => {
	const targetElement = document.querySelector(targetId);

	if (targetElement) {
		gsap.to(window, {
			scrollTo: {
				y: targetElement,
				autoKill: false, 
			},
			duration: 1.5,     
			ease: "power2.inOut"
		});
	}
};

export const initScroll = () => {
	//console.log(true)
	const links = document.querySelectorAll('a[href^="#"]');
	//console.log(links)
	links.forEach(link => {
		link.addEventListener("click", (event) => {
			//console.log('click')
			event.preventDefault();
			const targetId = link.getAttribute("href");
			if (targetId) scrollToSection(targetId);
		});
	});
};
